import Storage from '../externaljs/Storage';
import PostDataWithParamOptional from './PostDataWithParamOptional'; 
import Urls from '../externaljs/Urls';

export default function PostDataWithParam(url,params){
    // const API = "https://myrelo.us/relotechApi/public/Api/";
    //const API = "http://localhost/Relotech/relotechApi/public/Api/";

    //for staging server
    const API = "https://myrelo.us/stagingRelotechApi/public/Api/";

    let headers = new Headers();
    headers.append('Content-type','application/json');      
    headers.append('token',Storage.get('myReloProDriverLoginToken'));
  

    return  fetch(API+url,{
            method:'POST',
            headers:headers,
            body:JSON.stringify(params)
        })
        .then( res => res.json())
}