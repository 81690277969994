import React from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';
import Homepage from './components/homepage'
import ContactUs from './components/contactus'
import Urls from './externaljs/Urls'
import './App.css';
// import ForgotPassword from './components/ForgotPassword';
// import OtpCheck from './components/OtpCheck';
// import Login from './components/Login';
// import ResendActivation from './components/ResendActivation';

function App() {
  return (

    <HashRouter>
      <div className="App">

        <Route path="/" exact render={
          () => {
            return <Redirect to="/home" />
          }
        } />

        <Route path="/home" render={
          (props) => {
            return <Homepage {...props} />
          }
        } />

        <Route path="/driver" render={
          (props) => {
            window.location.href = Urls.driverHome;
            return null;
          }
        } />
        <Route path="/driverLogin" render={
          (props) => {
            window.location.href = Urls.driverHome + '#/login';
            return null;
            // return <Login {...props} />
          }
        } />

        <Route path="/helper" render={
          (props) => {
            window.location.href = Urls.helperHome;
            return null;
            // return <Helper {...props} />
          }
        } />

        <Route path="/helperLogin" render={
          (props) => {
            window.location.href = Urls.helperHome + '#/login';
            return null;
            // return <Login {...props} />
          }
        } />
        <Route path="/packer" render={
          (props) => {
            window.location.href = Urls.packerHome;
            return null;
            // return <Helper {...props} />
          }
        } />

        <Route path="/packerLogin" render={
          (props) => {
            window.location.href = Urls.packerHome + '#/login';
            return null;
            // return <Helper {...props} />
          }
        } />
        <Route path="/thirdparty" render={
          (props) => {
            window.location.href = Urls.thirdpartyHome;
            return null;
            // return <Helper {...props} />
          }
        } />
        <Route path="/thirdpartyLogin" render={
          (props) => {
            window.location.href = Urls.thirdpartyHome + '#/login';
            return null;
            // return <ContactUs {...props} />
          }
        } />
        <Route path="/claimrepair" render={
          (props) => {
            window.location.href = Urls.claimrepairHome;
            return null;
            // return <Helper {...props} />
          }
        } />
        <Route path="/claimrepairLogin" render={
          (props) => {
            window.location.href = Urls.claimrepairHome + '#/login';
            return null;
            // return <ContactUs {...props} />
          }
        } />

        <Route path="/contact-us" render={
          (props) => {
            return <ContactUs {...props} />
          }
        } />

        {/* <Route path="/forgot-password" component={ForgotPassword}/> */}

        {/* <Route path="/otp-check" component={OtpCheck} /> */}

        {/* <Route path="/login" component={Login} /> */}

        {/* <Route path="/resend-activation" component={ResendActivation} /> */}

      </div>
    </HashRouter>
  );
}

export default App;
