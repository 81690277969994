import Storage from "./Storage";
import { getDashboardUrl } from "./Urls";
// NOTE:- here name are always titilecases
const proProfileDetails = [
    {
        name: "Driver",
        profileId: 6
    },
    {
        name: "Helper",
        profileId: 5
    },
    {
        name: "Packer",
        profileId: 12
    },
    {
        name: "Thirdparty",
        profileId: 13
    },
    {
        name: "Claimrepair",
        profileId: 14
    }
];


export const setAuthDataInCookie = (result) => {
    const profileConfig = proProfileDetails.find(profile => profile.profileId == result.data.profile_id);
    if (profileConfig) {
        Storage.set(`myReloPro${profileConfig.name}LoginToken`, result.token);
        Storage.set(`myReloPro${profileConfig.name}LoginData`, JSON.stringify(result.data, ["id", "user_id", "profile_id", "first_name", "last_name", "email","first_login", "profile_image","trial_subscription_id","subscription_id","subscription_issue_date","subscription_expiry_date"]));
        //redirect to dashbord page
        window.location.href = getDashboardUrl(profileConfig.name);
    }
}
