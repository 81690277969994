import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import { FormGroupMaterial } from '../externaljs/FormGroupMaterial';
import { setAuthDataInCookie } from '../externaljs/Helper';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loggedIn: false,
            emailErr: '',
            passwordErr: '',
            loginErr: '',
            regSuc: '',
            processing: false,
            urlToken:''
        }
    }


    componentDidMount() {
        // if (typeof this.props.location.state !== 'undefined') {
        //     this.setState({ regSuc: this.props.location.state.regErr, loginErr: '' });
        // }
        FormGroupMaterial();
    }

    componentWillUnmount() {
        this.setState({ regSuc: '' });
        this.setState({ loginErr: '' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleActivationLogin= (e) => {
        this.setState({ processing: true }, () => {
            PostDataWithParam('AuthenticateDetails', { 'token': this.state.urlToken })
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        setAuthDataInCookie(result);
                        this.setState({ loggedIn: true });
                    } else {
                        this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                    }
                })
                .catch(err => {
                    this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                })
         })
    }
    handleLogin = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        }
        else if (!this.state.password) {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: 'Password is required.' });
        } else {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: '' });
            this.setState({ processing: true }, () => {
                PostDataWithParam('Authenticate', { email: this.state.email, password: this.state.password })
                    .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                            setAuthDataInCookie(result);
                            this.setState({ loggedIn: true });
                        } else {
                            this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                    })
            })
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary btn-block" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary btn-block">Login</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.loginErr}
                </div>
            )
        }
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.regSuc}
                </div>
            )
        }
    }

    render() {


        return (
            <section
            // className="login-register-bg"
            >
                <div className="login-register-box">
                    <div className="login-register-header-box text-center">
                        <div className="m-b-2">
                            <a href="http://myrelopro.com/" className="logo"><img src="assets/images/MyReloPro-logo-black.png" alt="" /></a>
                        </div>
                        {/* <div className="btn-group">
                            <Link to={createLinkByProfile(this.state.profileConfig.name,"/login")} className="btn border-slate text-slate-800 btn-flat text-uppercase active">Sign in</Link>
                            <Link to={{
                                pathname: createLinkByProfile(this.state.profileConfig.name,"/registration"),
                                state: { subscription_id: 5, amount: 0 }
                            }} className="btn border-slate text-slate-800 btn-flat text-uppercase">Sign up</Link>
                        </div> */}
                        <br />
                        {this.showAlert(this.state.loginErr)}
                        {this.showSuccessAlert(this.state.regSuc)}
                    </div>
                    <form action="#" method="POST" onSubmit={this.handleLogin}>
                        <div className="login-box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="email" className="form-control" placeholder="Email Address" name="email" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.emailErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Password</label>
                                        <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.passwordErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="form-group m-b-0 clearfix text-sm-center">
                                        {this.renderSubmitBtn()}&nbsp;
                            {/* <Link to={createLinkByProfile(this.state.profileConfig.name,"/forgot-password")} className="btn btn-link m-t-8">Forgot Password?</Link> */}
                                        <h6 className="text-muted m-t-10">OR</h6>
                                        <Link to="#" className="btn btn-link m-t-0 f-s-14 p-b-0" onClick={() => this.props.forgotPassModal()}>Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to="#" className="btn btn-link btn-block f-s-13 p-0" onClick={() => this.props.closeLoginModal()}><u>Didn’t receive activation email?</u></Link>
                                </div>
                            </div>
                        </div>
                        {/* <hr className="m-t-40 m-b-40" />
                        <div className="login-box-footer text-center">
                            <span className="text-grey-400"> Don't have an account?</span>
                            <Link to={{
                                pathname: createLinkByProfile(this.state.profileConfig.name,'/registration'),
                                state: { subscription_id: 5, amount: 0 }
                            }} className="link-btn">Sign Up Now</Link>
                        </div> */}
                    </form>
                </div>

            </section>
        );
    }
}

export default Login;